<template>
  <div>
    <NavTitle class="mb16" :title="showBack ? '返回' : '报名记录'" :showBack="showBack" @back="handleBack">
      <template #right>
        <div class="flex items-center">
          <el-date-picker
            class="mr10"
            v-model="screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleChange">
          </el-date-picker>
          <el-select class="mr10" v-model="searchType" placeholder="请选择查询类型">
            <el-option
              v-for="item in searchTypes"
              :key="item.type"
              :label="item.text"
              :value="item.type">
            </el-option>
          </el-select>
          <div class="input-row">
            <el-input class="input" placeholder="请输入手机号/ID" clearable v-model="searchWord" @change="handleChange"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"  @click="getEnrollList"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="pl20 pr20 mt16 pb20">
      <el-table
        :data="enrollList"
        style="width: 100%">
        <el-table-column
          prop="postId"
          label="职位ID">
        </el-table-column>
        <el-table-column
          prop="postTitle"
          label="职位名称">
        </el-table-column>
        <el-table-column
          prop="priceDesc"
          label="职位薪资">
        </el-table-column>
        <el-table-column
          prop="locationDesc"
          label="职位位置描述">
        </el-table-column>
        <el-table-column
          prop="enrollTime"
          label="报名时间">
        </el-table-column>
        <el-table-column
          prop="userHideMobile"
          label="报名用户">
          <template slot-scope="scope">
            <span class="theme-text pointer" @click="toSearchUser(scope.row.uid)">{{scope.row.userHideMobile}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="statusDesc"
          label="状态">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt16" v-if="enrollList.length > 0">
        <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import { searchEnrollsApi } from '@/api/admin.js'
export default {
  name: "EnrollRecord",
  components: {
    NavTitle,
    Pagination
  },
  data() {
    return {
      searchWord: '',
      enrollList: [],
      uid: '',
      curPage: 0,
      searchType: 1,
      searchTypes: [
        {text: '根据用户信息查询', type: 1},
        {text: '根据职位ID查询', type: 3}
      ],
      screenDate: []
    }
  },
  computed: {
    showBack() {
      return this.$route.query.uid ? true : false
    }
  },
  created() {
    if(this.$route.query.uid) this.searchWord = this.$route.query.uid
    this.getEnrollList()
  },
  beforeRouteUpdate(to, from, next) {
    this.handleBack()
    next()
  },
  methods: {
    getEnrollList() {
      const formData = {
        searchKey: this.searchWord.trim(),
        searchType: this.searchType,
        startPage: this.curPage
      }
      if(this.screenDate && this.screenDate.length > 0) {
        Object.assign(formData, {
          startTime: this.screenDate[0],
          endTime: this.screenDate[1]
        })
      }
      console.log('formData', formData)
      searchEnrollsApi(formData).then(res => {
        console.log('报名记录', res)
        const list = res.data.enrollLists || []
        if(list.length != 0 || this.curPage == 0) {
          this.enrollList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleChange() {
      this.curPage = 0
      this.enrollList = []
      this.getEnrollList()
    },
    nextPage() {
      this.curPage++
      this.getEnrollList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getEnrollList()
    },
    handleBack() {
      this.searchWord = ''
      this.getEnrollList()
    },
    toSearchUser(uid) {
      this.$router.push({
        path: '/user/list',
        query: {
          uid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
</style>